import { Fab, Modal, ThemeProvider, createTheme } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiFab: {
      // Name of the rule
      primary: {
        color: "#000000",
        backgroundColor: "#ffffff",
        "&:hover": {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
});

class CounterOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal1: false,
      modal2: false,
    };
  }
  render() {
    const handleClose = () => {
      this.setState({ modal1: false, modal2: false });
    };

    const stats = [
      {
        number: "+25.000",
        text: "Estudiantes que hemos ayudado",
      },
      {
        number: "+100",
        text: "Universidades en las que tenemos experencia",
      },
      {
        number: "+8",
        text: "Paises en los qe hemos trabajado",
      },
      {
        number: "+90%",
        text: "Estudiantes satisfechos",
      },
      {
        number: "+3",
        text: "Idiomas para gestionar tu servicio",
      },
    ];

    const testimonios = [
      {
        img: "/assets/images/landing/4_20240123_084528_0003.png",
        description:
          '"Los mejores resltados desde tu primer servicio al último"',
      },
      {
        img: "/assets/images/landing/5_20240123_084528_0004.png",
        description:
          '"Responsabilidad en los tiempos". Cero estrés con tus deberes',
      },
      {
        img: "/assets/images/landing/6_20240123_084528_0005.png",
        description: '"Los mejores tutores en nuestras areas"',
      },
    ];

    let Data = [
      {
        img: "/assets/images/landing/1_20240123_084526_0000.png",
        textButton: "MIRA ESTADÍSTICAS",
        linkButton: () => {
          this.setState({ modal1: true, modal2: false });
        },
        description:
          "Nuestra recopilación de datos a lo largo de nuestros 4 años de servicio.",
      },
      {
        img: "/assets/images/landing/2_20240123_084526_0001.png",
        textButton: "TESTIMONIOS",
        linkButton: () => {
          this.setState({ modal1: false, modal2: true });
        },
        description: "Lo que dicen los estudiantes de nuestros servicios.",
      },
      {
        img: "/assets/images/landing/3_20240123_084526_0002.png",
        textButton: "GARANTÍAS",
        linkButton: () => {},
        description:
          "Lo que necesitas saber cuando tomas un servicio de calidad y con garantías de satisfacción",
        link: "https://app.copywritecol.com/public/Q&A/partial",
      },
    ];

    return (
      <Fragment>
        <div
          className="row"
          style={
            isMobile
              ? {
                  display: "grid",
                  gap: "10px",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "1fr 1fr 1fr",
                }
              : {
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "1fr 1fr 1fr",
                }
          }
        >
          {Data.map((value, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={value.img} alt="" style={{ width: "200px" }} />
              {value.link ? (
                <a href={value.link} target="_blank" rel="noopener noreferrer">
                  <button
                    className="designer--btn"
                    style={{
                      width: "fit-content",
                      backgroundColor: "#ffbc00",
                      color: "black",
                    }}
                    onClick={value.linkButton}
                  >
                    {value.textButton}
                  </button>
                </a>
              ) : (
                <button
                  className="designer--btn"
                  style={{
                    width: "fit-content",
                    backgroundColor: "#ffbc00",
                    color: "black",
                  }}
                  onClick={value.linkButton}
                >
                  {value.textButton}
                </button>
              )}
              <p
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                {value.description}
              </p>
            </div>
          ))}
        </div>
        <Modal open={this.state.modal1} onClose={handleClose}>
          <div
            style={
              isMobile
                ? {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    padding: "2rem",
                    display: "flex",
                    placeContent: "center",
                    overflow: "scroll",
                  }
                : {
                    width: "80%",
                    height: "80%",
                    backgroundColor: "white",
                    marginLeft: "10%",
                    marginTop: "5%",
                    padding: "2rem",
                    display: "flex",
                    placeContent: "center",
                  }
            }
          >
            <ThemeProvider theme={theme}>
              <Fab
                color="primary"
                style={
                  isMobile
                    ? {
                        zIndex: 4,
                        position: "absolute",
                        right: 0,
                        marginRight: "0.5rem",
                      }
                    : {
                        zIndex: 4,
                        position: "absolute",
                        right: 0,
                        marginRight: "12%",
                      }
                }
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </ThemeProvider>
            <img
              src="/assets/images/landing/7_20240123_084528_0006.png"
              alt=""
              style={{
                width: "350px",
                position: "absolute",
                top: isMobile ? "-15%" : "-5%",
              }}
            />
            <img
              src="/assets/images/landing/8_20240123_084528_0007.png"
              alt=""
              style={{
                width: "350px",
                position: "absolute",
                bottom: isMobile ? "-15%" : 0,
              }}
            />
            <div
              style={
                isMobile
                  ? {
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      gridTemplateRows: "1fr 1fr 1fr 1fr 1fr",
                      alignContent: "center",
                      width: "100%",
                      marginTop: "32%",
                      marginBottom: "32%",
                    }
                  : {
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gridTemplateRows: "1fr 1fr 1fr",
                      alignContent: "center",
                      width: "100%",
                      marginTop: "7rem",
                      marginBottom: "5rem",
                    }
              }
            >
              {stats.map((s, index) => {
                if (index === 2) {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gridColumn: isMobile ? "1/2" : "1/3",
                        alignItems: "center",
                      }}
                    >
                      <h1
                        style={{
                          color: "#ffbc00",
                          fontWeight: "bolder",
                          marginBottom: "-15px",
                        }}
                      >
                        {s.number}
                      </h1>
                      <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                        {s.text}
                      </p>
                    </div>
                  );
                }
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "#ffbc00",
                        fontWeight: "bolder",
                        marginBottom: "-15px",
                      }}
                    >
                      {s.number}
                    </h1>
                    <p style={{ fontSize: "20px", fontWeight: "bolder" }}>
                      {s.text}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal>

        <Modal open={this.state.modal2} onClose={handleClose}>
          <div
            style={
              isMobile
                ? {
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    padding: "2rem",
                    display: "flex",
                    placeContent: "center",
                    overflow: "scroll",
                  }
                : {
                    width: "80%",
                    height: "80%",
                    backgroundColor: "white",
                    marginLeft: "10%",
                    marginTop: "5%",
                    padding: "2rem",
                    display: "flex",
                    placeContent: "center",
                  }
            }
          >
            <ThemeProvider theme={theme}>
              <Fab
                color="primary"
                style={
                  isMobile
                    ? {
                        zIndex: 4,
                        position: "absolute",
                        right: 0,
                        marginRight: "0.5rem",
                      }
                    : {
                        zIndex: 4,
                        position: "absolute",
                        right: 0,
                        marginRight: "12%",
                      }
                }
                onClick={handleClose}
              >
                <CloseIcon />
              </Fab>
            </ThemeProvider>
            <div>
              <div
                style={
                  isMobile
                    ? {
                        textAlign: "center",
                        fontSize: 25,
                        marginTop: "70px",
                        marginBottom: "-100px",
                      }
                    : {
                        textAlign: "center",
                        marginTop: "100px",
                        marginBottom: "-75px",
                        fontSize: 25,
                      }
                }
              >
                <b>"8.5 de 10 estudiantes nos recomiendan por estas razones"</b>
              </div>
              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.5rem",
                      }
                    : { display: "flex", gap: "1rem" }
                }
              >
                {testimonios.map((t, index) => {
                  return (
                    <div
                      style={
                        isMobile
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              marginBottom: -100,
                            }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                            }
                      }
                      key={index}
                    >
                      <img src={t.img} alt="" />
                      <p style={{ marginTop: "-100px", fontWeight: "bolder" }}>
                        {t.description}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default CounterOne;
