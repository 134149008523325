import React, { Component, Fragment } from "react";
import { isMobile } from "react-device-detect";
import { FiX, FiMenu } from "react-icons/fi";

class HeaderSix extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    this.stickyHeader = this.stickyHeader.bind(this);

    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  stickyHeader() {}
  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });

    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    return (
      <Fragment>
        {/* Start Header Area  */}
        <header
          style={{ backgroundColor: "black" }}
          className="header-area formobile-menu header--fixed default-color"
        >
          <div className="header-wrapper" id="header-wrapper">
            <div className="header-left">
              <div className="logo">
                <a href="/">
                  <img
                    className="logo-1"
                    src="/icon.png"
                    alt="LogoCopywrite"
                    width="60px"
                  />
                  <img
                    className="logo-2"
                    src="/icon.png"
                    alt="LogoCopywrite"
                    // width="60px"
                  />
                </a>
              </div>
            </div>
            <div className="header-right">
              <nav className="mainmenunav d-lg-block">
                <ul className="mainmenu" style={{ backgroundColor: "black" }}>
                  <li className="">
                    <a
                      style={{ fontSize: "20px", color: "white" }}
                      href="#home"
                      id="start-hover-menu"
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: "20px", color: "white" }}
                      href="#about"
                      id="start-hover-menu"
                    >
                      Acerca de
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: "20px", color: "white" }}
                      href="#servi"
                      id="start-hover-menu"
                    >
                      Cursos
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ fontSize: "20px", color: "white" }}
                      href="#contact"
                      id="start-hover-menu"
                    >
                      Contacto
                    </a>
                  </li>
                  <li style={{ display: "grid" }}>
                    <div
                      style={{
                        display: isMobile ? "block" : "grid",
                        placeSelf: "center",
                        placeItems: "center",
                      }}
                      className="header-btn"
                    >
                      <input
                        placeholder="Buscar..."
                        style={{ borderRadius: "400px", width: "60%" }}
                        type={"text"}
                      ></input>
                    </div>
                  </li>
                </ul>
              </nav>
              {/* Start Humberger Menu  */}
              <div className="humberger-menu d-block d-lg-none pl--20">
                <span
                  onClick={this.menuTrigger}
                  className="menutrigger text-white"
                >
                  <FiMenu color="white" />
                </span>
              </div>
              {/* End Humberger Menu  */}
              <div className="close-menu d-block d-lg-none">
                <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                  <FiX color="white" />
                </span>
              </div>
            </div>
          </div>
        </header>
        {/* End Header Area  */}
      </Fragment>
    );
  }
}

export default HeaderSix;
