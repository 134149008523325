// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import DesignerPortfolio from "./home/DesignerPortfolio";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import Parciales from "./Parciales";
import Tutorias from "./Tutorias";
import Talleres from "./Talleres";
import Agendate from "./Agendate";
import Prime from "./Prime";
import LoginTutores from "./LoginTutores";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Mantenimiento from "./Mantenimiento";
import Smart from "./Smart";
import MetaPixel from "./utils/meta/metaPixel";
// import CreativeLanding from "./home/CreativeLanding";

import {
  GarantiaCW,
  AgendadoParcialExpress,
  AgendadoParcial,
  AgendadoTutoria,
  AgendadoTaller,
} from "./agendado";
import Faq from "./faq";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <MetaPixel />
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/tutores`}
              component={LoginTutores}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/smart`}
              component={Smart}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/agendado-parcial-express`}
              component={AgendadoParcialExpress}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/garantia-cw`}
              component={GarantiaCW}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/agendado-parcial`}
              component={AgendadoParcial}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/agendado-tutoria`}
              component={AgendadoTutoria}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/agendado-taller`}
              component={AgendadoTaller}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/parciales`}
              component={Parciales}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/tutorias`}
              component={Tutorias}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/talleres`}
              component={Talleres}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/agendate`}
              component={Agendate}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/prime`}
              component={Prime}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mantenimiento`}
              component={Mantenimiento}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={Faq}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={DesignerPortfolio}
            />

            <Redirect to="/" />
          </Switch>
        </PageScrollTop>
        <ToastContainer />
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register({
  onUpdate: (registration) => {
    registration.unregister().then(() => window.location.reload());
  },
  onSuccess: (registration) => {
    console.info("service worker on success state");
    console.log(registration);
  },
});
