import React from "react";
import { FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import SmartFooter from "./component/footer/SmartFooter";
import HeaderSix from "./component/header/HeaderSix";
import TabThree from "./elements/tab/TabThree";
import ServiceTwo from "./elements/service/ServiceTwo";
import { isMobile } from "react-device-detect";

const Smart = () => {
  const SlideList = [
    {
      textPosition: "text-start",
      category: "",
      title: "No estudies más, ¡Estudia mejor!",
      description:
        "Smart es una nueva herramienta para que los estudiantes estudien solamente lo que tienen que estudiar para sus examenes",
      buttonText: "Estoy interesado",
      buttonLink: "https://wa.link/xvd7v5",
    },
  ];

  return (
    <div>
      <section>
        <HeaderSix />
      </section>
      <section>
        {/* <div className="main-content">
          <h1 className="main-content-tittle">No estudies mas estudia mejor</h1>
          <p className="main-content-text">
            Smart es una nueva herramienta para que los estudiantes estudien
            solamente lo que tienen que estudiar para sus examenes
          </p>
          <button className="main-content-button">Estoy interesado</button>
          <img
            className="main-content-img"
            src="https://www.aerocivil.gov.co/Style%20Library/CEA/img/img.jpg"
            alt=""
          ></img>
        </div> */}
        <div className="slider-activation slider-creative-agency" id="home">
          <div id="smart-main-content" data-black-overlay="6">
            {SlideList.map((value, index) => (
              <div
                style={{
                  paddingBottom: "50px",
                  paddingTop: isMobile ? "75px" : "140px",
                }}
                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                key={index}
              >
                <div className="container">
                  <div className="row">
                    <div
                      style={{ padding: "0px 0px" }}
                      id="smart-main-container"
                      className="col-lg-12"
                    >
                      <div
                        id="smart-tittle"
                        style={{
                          fontSize: "50px",
                        }}
                        className={`inner ${value.textPosition}`}
                      >
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1
                            style={{
                              fontSize: isMobile ? "2.5rem" : "50px",
                              lineHeight: "initial",
                              textAlign: "start",
                            }}
                            className="title theme-gradient good_font"
                          >
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p
                            style={{ padding: "0px 0px", textAlign: "start" }}
                            className="description good_font"
                          >
                            {value.description}
                          </p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div
                            style={{
                              justifyItems: "center",
                              display: "grid",
                              marginBottom: isMobile ? "" : "",
                            }}
                            className="slide-btn"
                          >
                            <div className="border-btn">
                              <a
                                style={{
                                  backgroundColor: "#ecb929",
                                  borderRadius: "200px",
                                }}
                                className="rn-button-style--2 btn-primary-color good_font "
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <img
                          className="img-main-smart"
                          src="/smart_1.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>
        <div className="row text-center smart-services" id="about">
          <div className="col-lg-12">
            <div className="tablist-inner">
              <h3
                style={{ fontSize: "50px", lineHeight: "initial" }}
                className="title theme-gradient good_font"
              >
                ¿Quienes somos?
              </h3>
            </div>
          </div>
        </div>
        <div>
          <div
            style={{ backgroundColor: "#000107", paddingTop: "40px" }}
            className="service-area creative-service-wrapper ptb--120 bg_color--1"
            id="service"
          >
            <div className="container">
              <div className="row creative-service">
                <div className="col-lg-12">
                  <ServiceTwo
                    item="6"
                    column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
                  />
                  <div>
                    {SlideList[0].buttonText ? (
                      <div
                        style={{
                          justifyItems: "center",
                          display: "grid",
                          marginTop: "50px",
                        }}
                        className="slide-btn"
                      >
                        <div className="border-btn">
                          <a
                            style={{
                              backgroundColor: "#ecb929",
                              borderRadius: "200px",
                            }}
                            className="rn-button-style--2 btn-primary-color good_font "
                            href={`${SlideList[0].buttonLink}`}
                          >
                            {SlideList[0].buttonText}
                          </a>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        {/* Start Designer Portfolio  */}
        <div
          style={{ backgroundColor: "#000107", paddingTop: "0px" }}
          className="designer-portfolio-area ptb--120 bg_color--1"
          id="servi"
        >
          <div className="wrapper plr--70 plr_sm--30 plr_md--30">
            <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
          </div>
        </div>
        {/* End Designer Portfolio  */}
      </section>
      <section className="smart-end" id="contact">
        <h2 className="good_font smart-end-tittle">¿NO ENCUENTRAS UN CURSO?</h2>
        <p className="good_font smart-end-text">
          ¡Escribenos! En smart siempre estamos buscando lo mejor para ti.
          {/* ¡ESCRIBENOS! EN SMART SIEMPRE ESTAMOS BUSCANDO LO MEJOR PARA TI */}
        </p>
      </section>
      <footer>
        <SmartFooter />
      </footer>
      <div className="backto-top">
        <ScrollToTop style={{ backgroundColor: "#333" }} showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </div>
  );
};
export default Smart;
