import React from "react";
import imgTutores from "../public/img/logTutores.jpg";
import logo from "../public/img/icon.png";
import "../public/assets/css/styles.css";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

const LoginTutores = () => {
    return (
        <div className="login">
            <div className="movil__header">
                <div className="movil__volver">
                    <Link to="/">
                        <BiArrowBack className="movil__volver--icon" />
                    </Link>
                </div>
                <div className="movil__img">
                    <img src={logo} alt="logo" style={{ padding: 10 }} />
                </div>
            </div>
            <div className="login__img">
                <div>
                    <Link to="/">
                        <BiArrowBack className="login__img--icon" />
                    </Link>
                    <h3>Volver</h3>
                </div>
                <img src={imgTutores} alt="tutores" style={{ borderRadius: "10%" }} />
            </div>
            <div className="login__form">
                <div className="login__form--img" style={{ justifyContent: "center" }}>
                    <img src={logo} alt="logo" />
                </div>
                <div className="login__form--container">
                    <h3 style={{ textAlign: "center" }}>Inicio de Sesión Tutores</h3>
                    <div className="login__form--input">
                        <label>Usuario: </label>
                        <input type="text" />
                    </div>
                    <div className="login__form--input">
                        <label>Contraseña: </label>
                        <input type="password" />
                    </div>
                    <button type="button" style={{ borderRadius: 10 }}>
                        ¡Pronto!
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LoginTutores;
