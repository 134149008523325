import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";

class HeaderFour extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }
  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color, headerPosition } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Trydo" />;
    } else if (logo === "dark") {
      logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Trydo" />;
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-symbol-dark.png" alt="Trydo" />
      );
    } else if (logo === "all-dark") {
      logoUrl = <img src="/assets/images/logo/logo-all-dark.png" alt="Trydo" />;
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-symbol-light.png" alt="Trydo" />
      );
    } else if (logo === "logo-copywrite") {
      logoUrl = <img src="/icon.png" alt="LogoCopywrite" width="60px" />;
    } else {
      logoUrl = <img src="/assets/images/logo/logo.png" alt="Trydo" />;
    }

    return (
      <header
        className={`header-area formobile-menu ${headerPosition} ${color}`}
      >
        <div
          className="header-wrapper"
          id="header-wrapper"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          <div>
            <div className="logo">
              <a href="/">
                {logoUrl}
                <h4 style={{ display: "inline", marginLeft: "10px" }}>
                  Copywrite
                </h4>
              </a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <Link to="/">Home</Link>
                </li>
                {/* <li>
                  <HashLink
                    smooth
                    to="#services"
                    style={{ color: "rgb(255, 188, 0)" }}
                  >
                    ¡Compra aquí!
                  </HashLink>
                </li> */}
                <li>
                  <HashLink smooth to="/prime">
                    Copywrite Prime
                  </HashLink>
                </li>
                <li>
                  <HashLink smooth to="/smart">
                    Preparatorios
                  </HashLink>
                </li>
                {/*
                                    <li>
                                    <HashLink smooth to="#data">
                                        ¿Por qué confiar en nosotros?
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink smooth to="#payment">
                                        ¿Cómo funcionamos?
                                    </HashLink>
                                </li>
                                <li>
                                    <HashLink smooth to="#contact">
                                        Contacto
                                    </HashLink>
                                </li>
                                    */}
                {/* <li>
                  <a
                    smooth
                    target={"_blank"}
                    href="https://forms.monday.com/forms/44608466c0e14bda90bd6aee97e5d7ed?r=use1"
                    rel="noopener noreferrer"
                  >
                    ¿1er empleo?
                  </a>
                </li> */}
                <li>
                  <a
                    smooth
                    target={"_blank"}
                    href="https://app.copywritecol.com/public/hiring/form"
                    rel="noopener noreferrer"
                  >
                    Trabaja con nosotros
                  </a>
                </li>
                <li>
                  <a
                    href="https://app.copywritecol.com/auth/login-page"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ingreso Tutores
                  </a>
                </li>
              </ul>
            </nav>
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFour;
