import React from "react";

const Mantenimiento = () => {
    return (
        <div style={{ placeContent: "center", display: "flex" }}>
            <img
                src="https://static.vecteezy.com/system/resources/previews/004/968/534/non_2x/web-or-app-system-maintenance-optimization-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-vector.jpg"
                title=""
                alt=""
            />
        </div>
    );
};

export default Mantenimiento;
