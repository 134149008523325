import React, { useEffect } from "react";

const RedirectingPage = ({ url }) => {
  useEffect(() => {
    if (url) window.location.href = url;
  }, [url]);

  return (
    <div
      style={{
        textAlign: "center",
        color: "rgb(255, 188, 0)",
        backgroundColor: "#eee",
        height: "100vh",
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <b className="good_font" style={{ fontSize: "1.5rem" }}>
          Redireccionando...
        </b>
      </div>
    </div>
  );
};

export default RedirectingPage;
