import React, { Component } from "react";

const ServiceList = [
    {
        step: '1.',
        title: 'Cotiza',
        description: 'Ingresa a nuestra plataforma, dinos qué necesitas y te diremos el costo.'
    },
    {
        step: '2.',
        title: 'Paga',
        description: 'Escoge el método de pago que más te guste y realiza la transacción.'
    },
    {
        step: '3.',
        title: 'Agenda',
        description: 'Agendaremos al mejor profesor para tu requerimiento.'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <h2 className="title" style={{color: '#f9004d'}}>{val.step}</h2>
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;