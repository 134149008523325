import React from "react";

const Agendate = () => {
  return (
    <iframe
      title="agendate"
      id="typeform-full"
      width="100%"
      height="100%"
      frameBorder="0"
      allow="camera; microphone; autoplay; encrypted-media;"
      src="https://form.typeform.com/to/TOyKsmOD?typeform-medium=embed-snippet"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        border: 0,
      }}
    ></iframe>
  );
};

export default Agendate;
