import React from "react";
import ScrollToTop from "react-scroll-up";
import FooterThree from "../component/footer/FooterThree";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import ServiceList from "../elements/service/ServiceList";
import { Helmet } from "react-helmet";
import ServiceOne from "../elements/service/ServiceOne";
import CounterOne from "../elements/counters/CounterOne";
import ServiceWhyWe from "../elements/service/ServiceThree";
import "../../public/assets/css/styles.css";
import Fab from "@material-ui/core/Fab";
import { isMobile } from "react-device-detect";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { ThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiFab: {
      // Name of the rule
      primary: {
        backgroundColor: "#25d366",
        "&:hover": {
          backgroundColor: "#25d366",
        },
      },
    },
    MuiIcon: {
      root: {
        color: "white",
        backgroundColor: "white",
      },
    },
  },
});

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    description: "",
    buttonText: "¡Quiero Cotizar!",
    buttonLink: "",
  },
];

const DesignerPortfolio = () => {
  return (
    <div>
      <Helmet pageTitle="Copywrite">
        <title>Ayuda Trabajos parciales - Universidad Bogotá</title>
        <meta
          name="description"
          content="Te gustaría tener un amigo, inteligente, confiable que te ayude con tus
        trabajos de la u? Copywrite Te ayuda con Trabajos, Parciales, Exámenes ,
        Tutorías ,Talleres , Quices para tu universidad relájate y aprende
        rápido con Copywrite. Cotiza y conoce todos los beneficios que tenemos
        para ti"
        />
      </Helmet>
      {/* <h1 style={{ display: "none" }}>
        Te gustaría tener un amigo, inteligente, confiable que te ayude con tus
        trabajos de la u? Copywrite Te ayuda con Trabajos, Parciales, Exámenes ,
        Tutorías ,Talleres , Quices para tu universidad relájate y aprende
        rápido con Copywrite. Cotiza y conoce todos los beneficios que tenemos
        para ti
      </h1> */}
      {/* Start Header Area  */}
      <Header
        headerPosition="header--static"
        logo="logo-copywrite"
        color="color-black"
      />
      {/* End Header Area  */}

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--5 rn-slider-height"
            key={index}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5">
                  <div className="designer-thumbnail">
                    <img src="/icon.png" alt="Slider Images" />
                  </div>
                </div>
                <div className="col-lg-7 mt_md--40 mt_sm--40">
                  <div className={`inner ${value.textPosition}`}>
                    <h3 style={{ fontSize: "37px" }}>
                      ¿Necesitas aprobar tus asignaturas?
                    </h3>
                    <p style={{ fontSize: 30, fontWeight: "bolder" }}>
                      Te ayudamos con:
                    </p>
                    <div
                      style={
                        isMobile
                          ? {
                              display: "flex",
                              marginBottom: "10px",
                              flexDirection: "column",
                              gap: "0.5rem",
                            }
                          : {
                              display: "flex",
                              marginBottom: "10px",
                            }
                      }
                    >
                      <div
                        className="designer--btn--container"
                        style={{ height: "auto" }}
                      >
                        <a
                          href="https://app.copywritecol.com/public/forms/partial"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="designer--btn">Parciales</button>
                        </a>
                      </div>
                      <div
                        className="designer--btn--container"
                        style={{ height: "auto" }}
                      >
                        <a
                          href="https://app.copywritecol.com/public/forms/homework"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="designer--btn">Talleres</button>
                        </a>
                      </div>
                      <div
                        className="designer--btn--container"
                        style={{ height: "auto" }}
                      >
                        <a
                          href="https://app.copywritecol.com/public/forms/tutorship"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="designer--btn">Tutorias</button>
                        </a>
                      </div>
                    </div>
                    <h3
                      style={{
                        fontSize: "20px",
                        padding: "0px 22.5%",
                        color: "#f9004d",
                      }}
                    >
                      Te agendamos el mejor tutor con un 90% de exito académico
                    </h3>
                    <div
                      className="designer--btn--container"
                      style={{ height: "auto" }}
                    >
                      <a href="/#services">
                        <button
                          className="designer--btn"
                          style={{
                            backgroundColor: "#ffbc00",
                            color: "black",
                          }}
                        >
                          COMPRA AQUÍ
                        </button>
                      </a>
                    </div>
                    {/*{value.category ? <span>{value.category}</span> : ""}
                    <h1
                      className="title"
                      style={{ lineHeight: "0.8", marginBottom: 0 }}
                    >
                      Bienvenido a{" "}
                      <h1
                        className="title"
                        style={{ color: "#ffbc00", display: "inline" }}
                      >
                        Copywrite
                      </h1>
                    </h1>
                    <h2>
                      <h4 style={{ fontWeight: "normal" }}>
                        ¿Necesitas ayuda con tus tareas de la U ó Colegio?
                      </h4>
                      <TextLoop>
                        <span style={{ color: "#f9004d" }}> Parciales </span>
                        <span style={{ color: "#f9004d" }}> Talleres </span>
                        <span style={{ color: "#f9004d" }}> Tutorías </span>
                      </TextLoop>{" "}
                    </h2>

                    <h3>¡Aprueba tus asignaturas con Copywrite!</h3>
                    {value.description ? (
                      <p className="description">{value.description}</p>
                    ) : (
                      ""
                    )}
                    {value.buttonText ? (
                      <div className="designer--btn--container">
                        <HashLink smooth to="/#services">
                          <button className="designer--btn">
                            {value.buttonText}
                          </button>
                        </HashLink>
                      </div>
                    ) : (
                      ""
                    )}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>

      {/* Start Services  */}
      <div
        className="service-area creative-service-wrapper ptb--120 bg_color--1"
        id="services"
        name="services"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                <h2 id="servicesFocus" className="title theme-color">
                  ¿Qué necesitas?
                </h2>
                <h4>Solo haz click y cotiza tu servicio.</h4>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceList
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Services  */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area pt--25 pb--110 bg_color--5" id="data">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <h2 className="title mb--10 mt--10 theme-color">
                  ¿Por qué confiar en nosotros?
                </h2>
                <p style={{ color: "#ffbc00" }}>Te contamos</p>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
        {/* <div className="slide-btn text-center" style={{ marginTop: "20px" }}>
                    <button className="rn-button-style--2 btn-primary-color">
                        <HashLink smooth to="#services">
                            ¡Quiero cotizar!
                        </HashLink>
                    </button>
                </div> */}
        <div className="designer--btn--container">
          <a href="/#services">
            <button className="designer--btn">¡Quiero Comprar!</button>
          </a>
          {/* <a href="#services">
                        <button className="designer--btn">
                            ¡Quiero Cotizar!
                        </button>
                    </a> */}
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start why we?  */}
      <div
        className="service-area creative-service-wrapper ptb--120 bg_color--1"
        id="whywe"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                <h2 className="title theme-color">¿Por que con Copywrite?</h2>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceWhyWe
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End why we?   */}

      {/* Start Payment Process  */}
      <div
        className="service-area creative-service-wrapper ptb--120 bg_color--1"
        id="payment"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--30 mb_sm--0">
                <h2 className="title theme-color">Proceso de Compra Seguro</h2>
              </div>
            </div>
          </div>
          <div className="row creative-service">
            <div className="col-lg-12">
              <ServiceOne
                item="6"
                column="col-lg-4 col-md-6 col-sm-6 col-12 text-left"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Payment Process  */}

      {/* Start Footer Style  */}
      <FooterThree />
      {/* End Footer Style  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ThemeProvider theme={theme}>
          <Fab
            href="https://wa.link/xzgslm"
            className={
              isMobile ? "floating_wa_button_Mobile" : "floating_wa_button"
            }
            color="primary"
          >
            <WhatsAppIcon />
          </Fab>
        </ThemeProvider>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default DesignerPortfolio;
