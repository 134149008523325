import React, { Component } from "react";
import { GiTeacher, GiOpenBook } from "react-icons/gi";
import { IoIosPaper } from "react-icons/io";

const isInMaintenance = false;

const ServiceList = [
  {
    icon: <IoIosPaper color={{ backgroundColor: "#fff" }} />,
    title: "Parcial",
    description: "Con nosotros tienes un .80% de aprobación garantizada",
    backgroundColor: "#AEC4C1",
    path: isInMaintenance
      ? "/mantenimiento"
      : "https://app.copywritecol.com/public/forms/partial",
  },
  {
    icon: <GiTeacher />,
    title: "Tutoría",
    description: "Explicación paso a paso y conocimiento .Avanzamos a tu ritmo",
    backgroundColor: "#EF788E",
    path: isInMaintenance
      ? "/mantenimiento"
      : "https://app.copywritecol.com/public/forms/tutorship",
  },
  {
    icon: <GiOpenBook />,
    title: "Taller",
    description:
      "Calidad, presentación y garantía.De lo único que te arrepentirás es de no haberlo tomado antes",
    backgroundColor: "#F1D67B",
    path: isInMaintenance
      ? "/mantenimiento"
      : "https://app.copywritecol.com/public/forms/homework",
  },
];

class ServiceThree extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href={val.path} target="_blank" rel="noopener noreferrer">
                <div
                  className="service service__style--2"
                  style={{ backgroundColor: val.backgroundColor }}
                >
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3
                      className="title"
                      style={{ fontWeight: "bold", fontSize: "25px" }}
                    >
                      {val.title}
                    </h3>
                    <div style={{ marginBottom: "0.5rem", height: 90 }}>
                      <p style={{ lineHeight: "1.2rem" }}>
                        {val.description.split(".")[0]}
                      </p>
                      <p style={{ lineHeight: "1.2rem", fontWeight: "bolder" }}>
                        {val.description.split(".")[1]}
                      </p>
                    </div>
                    <div
                      className="designer--btn--container"
                      style={{ height: "auto" }}
                    >
                      <a
                        href={val.path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className="designer--btn">
                          ¡Quiero comprar!
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
