import React, { Component } from "react";
import { BiStats } from "react-icons/bi";
import { FaGraduationCap } from "react-icons/fa";
import { SiCashapp } from "react-icons/si";

const ServiceList = [
  {
    icon: <BiStats />,
    title: "OPTIMIZAR LAS HORAS DE ESTUDIO",
    description:
      "¡Aprobar nunca fue tan fácil! Con nuestra experiencia de 4 años, conocemos los temas más frecuentes de los exámenes de tu universidad y materia. ¡Únete a nosotros y prepárate para el éxito!",
  },
  {
    icon: <FaGraduationCap />,
    title: "ALCANCE SUS METAS",
    description:
      "¡Optimiza tu tiempo de estudio con Copywrite! Sabemos que cada minuto cuenta, por eso nos enfocamos en lo que realmente importa: que estudies lo que saldrá en tu próximo parcial.",
  },
  {
    icon: <SiCashapp />,
    title: "INVERSION",
    description:
      "¡Invierte en tu futuro profesional con Copywrite! Nuestros cursos son fundamentales para tu crecimiento. ¡Únete a nosotros y alcancemos tus metas juntos!",
  },
];

class ServiceTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-4 col-md-4 col-sm-4 col-12" key={i}>
                  <a href="/">
                    <div className="service service__style--2">
                      <div className="icon" style={{ color: "red" }}>
                        {val.icon}
                      </div>
                      <div className="content">
                        <h3 className="title">
                          <strong>{val.title}</strong>
                        </h3>
                        <p>{val.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
