import React, { useState } from "react";
import HeaderFour from "./component/header/HeaderFour";
import FooterThree from "./component/footer/FooterThree";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: isMobile ? "1rem 3rem 3rem 3rem" : "3rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // fontWeight: theme.typography.fontWeightRegular,
    fontWeight: "bold",
  },
}));

const Faq = () => {
  return (
    <div>
      <HeaderFour
        headerPosition="header--static"
        logo="logo-copywrite"
        color="color-black"
      />
      <Side />
      <Faqs />
      <FooterThree />
    </div>
  );
};

const Faqs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const classes = useStyles();
  return (
    <div
      style={
        isMobile
          ? { display: "flex", placeItems: "center", flexWrap: "wrap" }
          : {
              display: "flex",
              placeItems: "center",
            }
      }
    >
      <div
        style={
          isMobile ? { padding: "2rem 2rem 0px 2rem" } : { padding: "4rem" }
        }
      >
        <img
          style={
            isMobile
              ? { width: "3rem", height: "3rem" }
              : { width: "4rem", height: "4rem" }
          }
          alt="Icono faqs"
          data-entity-type="file"
          data-entity-uuid="a47eeaa8-8b9b-4360-b1c2-2e84a58fc8c7"
          src={require("../public/assets/images/ico-faqs(yellow).png")}
        ></img>
        <h1
          className="good_font"
          style={{ width: "50%", color: "rgb(255, 188, 0)" }}
        >
          General
        </h1>
      </div>
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cuáles son los horarios de atención de Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite estamos disponibles las 24 horas del día para
              ayudarte con tus necesidades de contenido. Nuestro personal de
              servicio al cliente está disponible para atenderte de 8 am a 5:30
              pm. Además, fuera de estas horas, puedes comunicarte con nosotros
              a través de nuestra plataforma online o chatbot. ¡En Copywrite
              siempre estamos disponibles para ti!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Qué garantía ofrecen por sus servicios en Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite, estamos comprometidos con la satisfacción de
              nuestros clientes y ofrecemos una garantía de 4.0 en todos los
              talleres y Pre-parciales que contrates con nosotros. Además, para
              las tutorías, te ofrecemos los primeros 15 minutos para que puedas
              reportar cualquier problema que tengas. Si no cumplimos con
              nuestra garantía, realizamos una devolución calculando el dinero
              que te devolvemos con una regla de 3 muy sencilla. En Copywrite,
              siempre cumplimos con nuestra garantía y nos aseguramos de que
              nuestros clientes estén satisfechos con nuestros servicios.
              ¡Confía en nosotros para brindarte una garantía real y cumplir con
              tus expectativas!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo puedo estar seguro de que obtendré buenos resultados al
              trabajar con Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite somos una empresa legalmente constituida con más de 4
              años de experiencia en el mercado. Hemos trabajado con más de 7500
              estudiantes en más de 80 universidades, lo que demuestra nuestra
              capacidad para brindar servicios de alta calidad. Además, puedes
              encontrar testimonios de estudiantes satisfechos con nuestros
              servicios en nuestro sitio web. ¡Trabaja con Copywrite y confía en
              que obtendrás los resultados que deseas!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Qué asignaturas manejan en Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite manejamos todas las materias de ingeniería y temas
              cuantitativos, desde asignaturas básicas hasta las más específicas
              de cualquier ingeniería. Además, contamos con más de 200 tutores
              asociados que están listos para ayudarte. Nos especializamos en
              las ingenierías y también brindamos servicios en áreas como
              idiomas, inglés, redacción y derecho. Si necesitas ayuda en alguna
              de estas áreas, ¡no dudes en contactarnos!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Qué experiencia tiene Copywrite en el mercado?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite tenemos una amplia experiencia en el mercado,
              brindando nuestros servicios de alta calidad durante más de 4
              años. Contamos con estudiantes satisfechos en más de 8 países que
              respaldan nuestra experiencia y capacidad para brindar servicios
              de excelencia. Puedes encontrar más información sobre nosotros y
              nuestros servicios en nuestra página web o en nuestra cuenta de
              Instagram. ¡Contáctanos y comprueba por ti mismo por qué somos la
              mejor opción para tus necesidades académicas!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo funciona el servicio de Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              En Copywrite hemos diseñado un proceso sencillo y eficiente para
              brindarte el mejor servicio posible. Nuestro proceso consta de 3
              simples pasos:
              <br />
              <br />
              <ol>
                <li>
                  1. Cotización: Ingresa a nuestro chatbot o plataforma en línea
                  para cotizar el servicio que necesitas.
                </li>
                <li>
                  2. Match: Nuestra plataforma hará match con el tutor ideal
                  para tus requerimientos, garantizando que recibas un servicio
                  personalizado y de calidad.
                </li>
                <li>
                  3. Pago y Entrega: Una vez que hayas sido emparejado con el
                  tutor adecuado, te contactaremos a través de WhatsApp o SMS
                  para que realices el pago y agendes la fecha de tu servicio.
                  Te pediremos cualquier material de preparación que necesites
                  para asegurarnos de que tu servicio salga perfecto.
                  Finalmente, el día de ejecución de tu servicio, te
                  entregaremos el trabajo final con la más alta calidad posible.
                </li>
              </ol>
              <br />
              En cualquier momento del proceso, puedes escribirnos si tienes
              preguntas o inquietudes, ¡estamos aquí para ayudarte! En Copywrite
              nos esforzamos por ofrecerte el mejor servicio y una experiencia
              satisfactoria. ¡Contáctanos hoy y descubre cómo podemos ayudarte
              con tus necesidades académicas!
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              ¿Cómo funciona el proceso de pago en Copywrite?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Contamos con dos opciones para realizar el pago de nuestros
              servicios: la opción manual y la opción de pasarela de pagos. En
              la opción manual, puedes hacer transferencias a nuestras cuentas
              bancarias (Nequi, Bancolombia, Daviplata, PSE, Efecty, Baloto,
              Paypal o Western Union). En la opción de pasarela de pagos, puedes
              hacer el pago a través de nuestra plataforma segura con cualquier
              entidad bancaria o tarjeta de crédito Visa, Mastercard o American
              Express. Queremos que tengas la tranquilidad de que tu proceso de
              pago será seguro y confiable en todo momento.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

const Side = () => {
  return (
    <div
      style={
        isMobile
          ? {
              display: "flex",
              flexWrap: "wrap",
              background: "rgb(255, 188, 0)",
              placeItems: "center",
            }
          : {
              display: "flex",
              height: "25rem",
              background: "rgb(255, 188, 0)",
              placeItems: "center",
            }
      }
    >
      <div style={isMobile ? { padding: "2.5rem" } : { padding: "5rem" }}>
        <img
          style={
            isMobile
              ? { width: "3rem", height: "3rem" }
              : { width: "4rem", height: "4rem" }
          }
          alt="Icono faqs"
          data-entity-type="file"
          data-entity-uuid="a47eeaa8-8b9b-4360-b1c2-2e84a58fc8c7"
          src={require("../public/assets/images/ico-faqs.png")}
        ></img>
        <h1
          className="good_font"
          style={
            isMobile
              ? { color: "white", fontSize: "3.125rem" }
              : { width: "50%", color: "white", fontSize: "4.72rem" }
          }
        >
          Preguntas Frecuentes
        </h1>
      </div>
      <div
        style={
          isMobile ? { padding: "0px 3rem 3rem 3rem" } : { padding: "4rem" }
        }
      >
        {isMobile ? (
          <iframe
            // width=""
            // height="315"
            src="https://www.youtube.com/embed/5IVy_L2Hl_g"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/5IVy_L2Hl_g"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default Faq;
