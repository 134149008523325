import React, { useState } from "react";
import logo from "../../public/img/icon.png";
import { Link } from "react-scroll";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../../public/assets/css/styles.css";
import { AiOutlineArrowLeft, AiOutlineArrowUp } from "react-icons/ai";
import ScrollToTop from "react-scroll-up";

const AgendadoParcialExpress = () => {
    const [acceptButton, setAcceptButton] = useState(false);
    const history = useHistory();

    const accept = () => {
        toast.success("¡Genial! 🔥", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        setAcceptButton(true);
    };

    return (
        <div className="agendado__container">
            <div className="arrow--container">
                <AiOutlineArrowLeft 
                    className="arrow"
                    onClick={() => history.push("/")}
                />
            </div>
            <section id="section1">
                <div className="agendado__container--header">
                    <img src={logo} alt="logo" />
                </div>
                <div className="agendado__container--title" style={{ marginTop: 0 }}>
                    <h4>
                        ¡Has sido agendado en <b style={{ color: "#f9004d" }}>Parciales Express</b> Copywrite!
                    </h4>
                </div>
                <div className="agendado__container--parrafo">
                    <h3 className="parrafo--item">
                        Lee la siguiente información, es muy importante ya que de esto depende el buen resultado de tu{" "}
                        <strong>Parcial Express</strong>.
                    </h3>
                </div>
            </section>
            <section id="section2">
                <div className="reglamentos">
                    <Link activeClass="active" to="section3" spy={true} smooth={true} duration={500}>
                        <h3
                            className="reglamentos--item3"
                            style={{ padding: "1rem", textAlign: "center"}}
                        >
                            1. Material de Preparación
                        </h3>
                    </Link>
                    <Link activeClass="active" to="section4" spy={true} smooth={true} duration={500}>
                        <h3
                            className="reglamentos--item1"
                            style={{ padding: "1rem", textAlign: "center"}}
                        >
                            2. Reglamento
                        </h3>
                    </Link>
                    <Link activeClass="active" to="section5" spy={true} smooth={true} duration={500}>
                        <h3
                            className="reglamentos--item2"
                            style={{ padding: "1rem", textAlign: "center"}}
                        >
                            3. Instrucciones
                        </h3>
                    </Link>
                    <Link activeClass="active" to="section6" spy={true} smooth={true} duration={500}>
                        <h3
                            className="reglamentos--item4"
                            style={{ padding: "1rem", textAlign: "center"}}
                        >
                            4. Garantía
                        </h3>
                    </Link>
                </div>
                <div className="dudas">
                    <h4>
                        ¿Tienes dudas?, escribe al
                        <a
                            href="https://api.whatsapp.com/send?phone=573224120987&text=Buenos%20d%C3%ADas%20*Copywrite*"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <strong> Whatsapp</strong>
                        </a>
                    </h4>
                </div>
                <div className="parrafo__confirm">
                    <h4>
                        Confirmo que estoy de acuerdo, que he leido y adjuntado toda la información solicitada.
                    </h4>
                </div>
                <div className="agendado__button--aceptar">
                    <button
                        onClick={accept}
                        disabled={acceptButton}
                        style={{ backgroundColor: acceptButton ? "gray" : "#ffbd00" }}
                    >
                        Aceptar
                    </button>
                </div>
                <div className="politica">
                    <h3>
                        Consulta{" "}
                        <a
                            href="https://cw-web-assets.s3.amazonaws.com/Terminos+y+Condiciones+Estudiantes.pdf"
                            target={"_blank"}
                        >
                            <strong>TyC</strong>
                        </a>{" "}
                        y{" "}
                        <a
                            href="https://cw-web-assets.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidad+de+Datos.pdf"
                            target={"_blank"}
                        >
                            <strong>Politica Privacidad</strong>
                        </a>
                    </h3>
                </div>
            </section>
            <section id="section3">
                <div className="pdf--container">
                    <img
                        alt=""
                        src={require("../../public/assets/images/agendado/parcial-express/material_preparacion.png")}
                    />
                </div>
            </section>

            <section id="section4">
                <div className="pdf--container">
                    <img alt="" src={require("../../public/assets/images/agendado/parcial-express/reglamento_1.png")} />
                    <img alt="" src={require("../../public/assets/images/agendado/parcial-express/reglamento_2.png")} />
                    <img alt="" src={require("../../public/assets/images/agendado/parcial-express/reglamento_3.png")} />
                </div>
            </section>
            <section id="section5">
                <div className="pdf--container">
                    <img
                        alt=""
                        src={require("../../public/assets/images/agendado/parcial-express/instrucciones.png")}
                    />
                </div>
            </section>
            <section id="section6">
                <div className="pdf--container">
                    <img
                        alt=""
                        src={require("../../public/assets/images/agendado/parcial-express/garantia.png")}
                    />
                </div>
            </section>
            <div className="up--container">
                <ScrollToTop showUnder={130}>
                    <AiOutlineArrowUp className="up--arrow"/>
                </ScrollToTop>
            </div>
        </div>
    );
};

export default AgendadoParcialExpress;
