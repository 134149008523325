import React from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

import "../../public/assets/css/styles.css";
import logo from "../../public/img/icon.png";

const GarantiaCW = () => {

  const history = useHistory();
 
  return (
    <div className="agendado__container">
      <div className="arrow--container">
        <AiOutlineArrowLeft
          className="arrow"
          onClick={() => history.push("/")}
        />
      </div>
      <section id="section1">
        <div className="agendado__container--header">
          <img src={logo} alt="logo" />
        </div>
        <div className="agendado__container--title" style={{ marginTop: 0 }}>
          <h4>
            ¿Conoces la <b style={{ color: "#f9004d" }}>Garantía Copywrite?</b>
          </h4>
        </div>
      </section>
      <section id="section2">
        <div className="pdf--container">
          <img
            alt=""
            src={require("../../public/assets/images/agendado/taller/garantia.png")}
          />
        </div>
      </section>
      <div className="garantia__button--aceptar">
        <a href="https://9sjgx8jx8ov.typeform.com/to/TOyKsmOD?typeform-source=trello.com">
          <button>
            ¿Quieres agendar?
          </button>
        </a>
      </div>
    </div>
  );
};

export default GarantiaCW;
