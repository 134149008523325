import React, { Component } from "react";
import { BiRocket, BiPurchaseTag, BiNews } from "react-icons/bi";
import "../../../public/assets/css/styles.css"

const ServiceList = [
  {
    icon: <BiRocket />,
    title: "Agenda en 15 minutos",
    description: "Una vez realizas tu solicitud tendrás respuesta en maximo 15 minutos",
  },
  {
    icon: <BiNews />,
    title: "Tenemos todas las materias de Ingenieria",
    description: "Te podemos ayudar en todas las materias que estas viendo este semestre",
  },
  {
    icon: <BiPurchaseTag />,
    title: "Precio Justo",
    description: "Cobramos lo mismo o menos que otros servicios que no te brindan ninguna garantía o seguridad.",
  },
];

class ServiceWhyWe extends Component {
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column} whywe`} key={i}>
                <div className="whywe--item">
                  <div className="icon text-center">{val.icon}</div>
                  <div className="content">
                    <h3 className="title text-center">{val.title}</h3>
                    <p 
                      className="text-center"
                      style={{ lineHeight: "1.2rem" }}
                    >{val.description}</p>
                  </div>
                </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceWhyWe;
