/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import { HashLink } from "react-router-hash-link";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function FooterThree() {
  const openWindow = (e, url, name) => {
    e.preventDefault();
    window.open(url, name);
  };

  return (
    <footer className="footer-area">
      <div
        className="footer-wrapper p-4"
        style={{ backgroundColor: "#ffbc00" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <img src="/icon.png" alt="Logo Copywrite" width="100px" />
          <h2 style={{ color: "white", display: "inline", marginLeft: "10px" }}>
            Copywrite
          </h2>
        </div>
        <Container>
          <Row>
            <Col>
              <h3 style={{ color: "white" }}>Información</h3>
              <ul style={{ listStyle: "none", padding: "0" }}>
                <li style={{ color: "white", fontSize: "16px" }}>
                  <HashLink
                    style={{ color: "white", fontSize: "16px" }}
                    smooth
                    to="/faq"
                  >
                    Preguntas frecuentes
                  </HashLink>
                </li>
                <li style={{ color: "white", fontSize: "16px" }}>
                  <HashLink
                    style={{ color: "white", fontSize: "16px" }}
                    smooth
                    to="#payment"
                  >
                    Trabaja con nosotros
                  </HashLink>
                </li>
                {/* <li style={{ color: "white", fontSize: "16px" }}>
                  Escribenos
                  {/* <a
                    href="https://www.instagram.com/copywrite.col/"
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a> */}
                {/* </li> */}
                <li
                  style={{
                    color: "white",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Chia, Colombia
                </li>
              </ul>
            </Col>
            <Col>
              <h3 style={{ color: "white" }}>Contáctanos</h3>
              <a
                href="https://www.instagram.com/copywrite.educacion?igsh=MXgzOHpiYXAwenRwYw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "10px",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://facebook.com/col.copywrite"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "10px",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/copywritecolombia"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "10px",
                    color: "white",
                  }}
                />
              </a>

              <a
                href="https://wa.link/xzgslm"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaWhatsapp
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "10px",
                    color: "white",
                  }}
                />
              </a>
            </Col>
            <Col>
              <h3 style={{ color: "white" }}>Políticas</h3>
              <a
                style={{ color: "white", fontSize: "16px" }}
                href="#"
                onClick={(e) =>
                  openWindow(
                    e,
                    "https://cw-web-assets.s3.amazonaws.com/Terminos+y+Condiciones+Estudiantes.pdf",
                    "Privacy"
                  )
                }
              >
                Política de privacidad
              </a>
              <br />
              <a
                style={{ color: "white", fontSize: "16px" }}
                href="#"
                onClick={(e) =>
                  openWindow(
                    e,
                    "https://cw-web-assets.s3.amazonaws.com/Pol%C3%ADtica+de+Privacidad+de+Datos.pdf",
                    "Terms"
                  )
                }
              >
                Términos y condiciones
              </a>
            </Col>
            <Col>
              <h3 style={{ color: "white" }}>Apoyados por</h3>
              <Container>
                <Row>
                  <Col xs={8}>
                    <img
                      src="/alianza.png"
                      alt="alianza"
                      width="100%"
                      style={{ objectFit: "cover" }}
                    />
                  </Col>
                  <Col xs={4}>
                    <img src="/alianza-2.png" alt="alianza" width="100%" />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
