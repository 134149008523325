import React, { Component, Fragment } from "react";
import { FaPhoneAlt } from "react-icons/fa";

class SmartFooter extends Component {
  render() {
    return (
      <Fragment>
        <div className="smart-footer-content">
          <div className="smart-footer-info">
            <img
              className="smart-footer-logo"
              src="img/icon.png"
              alt="LogoCopywrite"
            ></img>
            {/* <img className="smart-footer-logo" alt="" src="/smart_text.png" /> */}
            <p className="smart-footer-tag good_font">
              Tu futuro es nuestra meta
            </p>
            <p className="smart-footer-phone good_font">
              <FaPhoneAlt size={"2rem"} />
              {`+57 313 465 7403`}
            </p>
            <p className="smart-footer-email good_font">
              Julian@copywritecol.com
            </p>
            <div className="border-btn">
              <a
                style={{
                  backgroundColor: "#ecb929",
                  height: "40px",
                  padding: "5px 15px",
                }}
                className="rn-button-style--2 btn-primary-color good_font "
                href={`/`}
              >
                {`COPYWRITE`}
              </a>
            </div>
          </div>
          <div className="smart-footer-contact">
            <p className="smart-footer-contactus good_font">
              Dejanos tus datos
            </p>
            <hr class="smart-solid"></hr>
            <div>
              <input
                placeholder="Enter your e-mail"
                className="smart-footer-input"
                type="text"
              ></input>
            </div>
            <div className="border-btn">
              <a
                style={{
                  backgroundColor: "#ecb929",
                  height: "40px",
                  padding: "5px 15px",
                  marginTop: "15px",
                }}
                className="rn-button-style--2 btn-primary-color good_font "
                href={`/`}
              >
                {`Enviar`}
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SmartFooter;
