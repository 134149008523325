import React from "react";
import headerImg from "../public/img/prime-header.jpg";
import iconImg from "../public/img/icon.png";
import salesImg from "../public/img/sales.png";
import folderImg from "../public/img/folder.png";
import voiceImg from "../public/img/voice.png";
import cashbackImg from "../public/img/cashback.png";
import diaryImg from "../public/img/diary.png";

import { AiOutlineCheck } from "react-icons/ai";

const Prime = () => {
    return (
        <div>
            <header className="Prime-Header u-relative">
                <img src={headerImg} alt="header" className="Prime-Header-img" />
                <div className="Prime-Header-brand u-absolute u-container">
                    <img src={iconImg} alt={"Copywrite PRIME"} />
                    <h1>Copywrite PRIME</h1>
                </div>
            </header>

            <section className="Prime-Checks u-container">
                <ul>
                    <li>
                        <AiOutlineCheck />
                        <p>
                            <strong>10%</strong> descuento en todos los sevicios.
                        </p>
                    </li>
                    <li>
                        <AiOutlineCheck />
                        Acceso ilimitado a recursos academicos (Parciales, talleres) de materias que estas viendo.
                    </li>
                    <li>
                        <AiOutlineCheck />
                        <p>
                            <strong>¡Saltate la fila!</strong> Atención inmediata en caso de que la requieras.
                        </p>
                    </li>
                    <li>
                        <AiOutlineCheck />
                        <p>
                            <strong>Cashback</strong> Te devolvemos parte de tu compra con Startups aliadas.
                        </p>
                    </li>
                    <li>
                        <AiOutlineCheck />
                        <p>
                            <strong>Agenda sin pagar</strong> Agenda tus servicios pagando inicialmente solo una parte.
                        </p>
                    </li>
                </ul>

                <div className="Prime-TermsConditions">
                    <p>Al suscribirte aceptas</p>
                    <a
                        href="https://cw-web-assets.s3.amazonaws.com/Terminos_Y_Condiciones_Copywrite_Prime.pdf"
                        target={"_blank"}
                    >
                        Terminos & Condiciones
                    </a>
                </div>

                <div className="Prime-Subscribe">
                    <p>
                        $10.000 <span>/ mes</span>
                    </p>
                    <a
                        href="https://api.whatsapp.com/send?phone=573012703884&text=%C2%A1Hola!,%20Quiero%20adquirir%20Copywrite%20Prime%20%F0%9F%9A%80"
                        target={"_blank"}
                        className="Prime-Button"
                    >
                        Suscribirme
                    </a>
                </div>
            </section>

            <section className="Prime-Known u-container">
                <h2>Conoce PRIME</h2>
                <div className="Prime-Known-item">
                    <h3>10% descuento en todos los sevicios.</h3>
                    <p>TODOS los servicios que compres tendrán descuento, no importa el valor de la compra. </p>
                    <div>
                        <img src={salesImg} alt="sales" />
                    </div>
                </div>
                <div className="Prime-Known-item">
                    <h3>Recursos academicos ilimitados</h3>
                    <p>
                        Tendrás acceso a TODOS los recursos academicos propiedad de Copywrite (Talleres, parciales,
                        Pre-parciales). Acá encontrarás ejercicios con solución para estudiar en tus parciales
                    </p>
                    <div>
                        <img src={folderImg} alt="folder" />
                    </div>
                </div>
                <div className="Prime-Known-item">
                    <h3>¡Saltate la fila!</h3>
                    <p>
                        Requieres atención inmediata para algo de ultimo momento? No te preocupes, te atenderemos
                        inmediatamente
                    </p>
                    <div>
                        <img src={voiceImg} alt="voice" />
                    </div>
                </div>
                <div className="Prime-Known-item">
                    <h3>Cashback</h3>
                    <p>
                        ¡Recibe de vuelta entre el <strong>10% y 40%</strong> de la compra que hiciste!. Podrás
                        convertirlo en cosas que te gustan: Viajes, Restaturantes, Bares.{" "}
                    </p>
                    <div>
                        <img src={cashbackImg} alt="cashback" />
                    </div>
                </div>
                <div className="Prime-Known-item">
                    <h3>Agenda sin pagar</h3>
                    <p>
                        No tienes el dinero para pagar pero necesitas que te ayudemos? ¡Te damos credito!, pagas un
                        porcentaje ahora y el restante recien solucionemos tu solicitud
                    </p>
                    <div>
                        <img src={diaryImg} alt="diary" />
                    </div>
                </div>

                <div className="Prime-Subscribe">
                    <p>
                        $10.000 <span>/ mes</span>
                    </p>
                    <a
                        href="https://api.whatsapp.com/send?phone=573012703884&text=%C2%A1Hola!,%20Quiero%20adquirir%20Copywrite%20Prime%20%F0%9F%9A%80"
                        target={"_blank"}
                        className="Prime-Button"
                    >
                        Suscribirme
                    </a>
                </div>
            </section>
        </div>
    );
};

export default Prime;
